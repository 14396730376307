'use client'
import Link from "next/link";
import { useRouter, usePathname} from "next/navigation";
import { navList } from "../helper/navList";
import styles from "../styles/Footer.module.css";
import { useEffect, useState } from "react";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import CookieConsent, { Cookies } from "react-cookie-consent";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";

export default function Footer() {
  const pathname=usePathname()
  var nextPage = "/";
  const [policy, setpolicy] = useState("");
  const [policyPage, setpolicyPage] = useState("/policies");
  var bGdColor = require("../../styles/config.json");

  useEffect(() => {
    if (pathname == "/policies") {
      setpolicy("Cookie Policy");
      setpolicyPage("/policies/cookie");
    } else {
      setpolicy("Privacy Policy");
      setpolicyPage("/policies");
    }
  }, [pathname]);

  findKey(navList, pathname);

  function findKey(ary:Array<{ id: number; navLinkTitle: string;route:string }>, findVal:string) {
    var pos = 0;
    // Loop over the array
    for (var i = 0; i < ary.length; i++) {
      // Loop over objects keys
      for (var key in ary[i]) {
        // Compare current key value against passed value to find
        if (ary[i][key] === findVal) {
          if (i == 5) {
            pos = 0;
          } else {
            pos = i + 1;
          }
        }
      }
    }
    nextPage = navList[pos].route;
  }

  return (
     !pathname!.includes("admin")?
    <footer className={styles.footer}>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="IAmPresentingWebsiteCookie"
        style={{ background: "white", color: bGdColor.secondBackgroundColor }}
        buttonStyle={{
          color: "white",
          fontSize: "13px",
          background: "red",
          borderRadius: "5px",
        }}
        expires={150}
      >
        This website uses cookies to enhance the user experience. By continuing
        to browser the site you are agreeing to use of cookies outlined in our
        cookie policy.
        <Link legacyBehavior href="/policies/cookie">
          <a
            style={{
              fontSize: "15px",
              color: "red",
              textDecoration: "underline",
              marginLeft: "5px",
            }}
          >
            View More
          </a>
        </Link>
      </CookieConsent>
      <div className={styles.icon1}>
        <div>
          <a
            href="mailto:info@iampresenting.com?subject=Service Enquiry"
            aria-label="IAMPRESENTING Email"
          >
            <MailOutlineRoundedIcon fontSize="large" />
          </a>
        </div>
        <div> Mail Us</div>
        <div className={styles.email}>info@iampresenting.com</div>
      </div>
      <div className={styles.icon2}>
        <Link aria-label='IAmPresenting arrow' href={nextPage} passHref>
          <ArrowDownward  />
        </Link>
      </div>
      <Link href={policyPage} passHref>
        <div className={styles.icon3}>
          <div> {policy}</div>
        </div>
      </Link>
    </footer>
    :
    null
  );
}
