export const navList:Array<{ id: number; navLinkTitle: string;route:string }> = [
  {
    id: 1,
    navLinkTitle: "LET'S TALK",
    route: "/contact",
  },
  {
    id: 2,
    navLinkTitle: "ABOUT US",
    route: "/about",
  },
  {
    id: 3,
    navLinkTitle: "OUR SERVICES",
    route: "/services",
  },
  {
    id: 4,
    navLinkTitle: "Our Clients",
    route: "/clients",
  },
  {
    id: 5,
    navLinkTitle: "OUR MISSION",
    route: "/mission",
  },
  {
    id: 6,
    navLinkTitle: "OUR TEAM",
    route: "/team",
  },
];

export const appBarNavList = [
  {
    id: 1,
    navLinkTitle: "About Us",
    route: "/about",
  },
  {
    id: 2,
    navLinkTitle: "Services",
    route: "/services",
  },
  {
    id: 3,
    navLinkTitle: "Our Packages",
    route: "/packages",
  },
  {
    id: 4,
    navLinkTitle: "Our Clients",
    route: "/clients",
  },
  {
    id: 5,
    navLinkTitle: "Our Team",
    route: "/team",
  },
  {
    id: 6,
    navLinkTitle: "Contact Us",
    route: "/contact",
  },
];
