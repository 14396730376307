'use client'
import Link from "next/link";
import PropTypes from "prop-types";
import {usePathname} from "next/navigation";
import styles from "../styles/SideNav.module.css";

export default function SideNavButton(props:any) {

  function CurrentPath() {
    const pathname = usePathname()
    var cssName = styles.sideNavNumButton;
    var navLinkName = props.navLinkTitle;
    if (pathname == props.route) {
      cssName = styles.sideNavButtonActive;
      navLinkName = props.navLinkTitle;
    } else if (
      (props.id == 1 && pathname == "/") ||
      (props.id == 1 && pathname?.includes("/policies"))
    ) {
      cssName = styles.sideNavButton;
      navLinkName = "LET'S TALK";
    } else if (props.id == 3 && pathname?.includes("/packages")) {
      cssName = styles.sideNavButton;
      navLinkName = "Our Packages";
    } else {
      cssName = styles.sideNavNumButton;
      navLinkName = "0" + props.id.toString();
    }

    return (
      <div className={cssName}>
        <span>{navLinkName}</span>
      </div>
    );
  }

  return (
    <div className={styles.sideNavButtonContainer}>
      <Link href={props.route} key={props.id} passHref>
        {CurrentPath()}
      </Link>
    </div>
  );
}

SideNavButton.propTypes = {
  id: PropTypes.any,
  navLinkTitle: PropTypes.string,
  route: PropTypes.string,
};
