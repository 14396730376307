'use client'
import Link from "next/link";
import Image from "next/legacy/image";
import * as motion from "@/app/libraries/framer-motion";
import { appBarNavList } from "../helper/navList";
import styles from "../styles/AppBar.module.css";
import { usePathname } from "next/navigation";

export default function BaseAppBar() {
  const pathname = usePathname()
  var bGdColor = require("../../styles/config.json");
  //animate props
  const fadeInUp = {
    initial: {
      y: 60,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
        ease: 'linear',
      },
    },
  };

  function navState(e:any) {
    var menu = document.querySelector("." + styles.menu);
    var btn = document.querySelector("." + styles.btn);
    var menuList = document.querySelector("." + styles.menu_list);
    var gridDot = document.querySelector("." + styles.gridDot);
    var hrLine1 = document.querySelector("." + styles.hrLine1);
    var slogan1 = document.querySelector("." + styles.slogan1);
    var hrLine2 = document.querySelector("." + styles.hrLine2);
    var slogan2 = document.querySelector("." + styles.slogan2);
    var tube = document.querySelector("." + styles.tube);
    var cube = document.querySelector("." + styles.cube);
    var cubeWithBall = document.querySelector("." + styles.cubeWithBall);

    btn?.classList.toggle(styles.active);
    menu?.classList.toggle(styles.active);
    menuList?.classList.toggle(styles.active);
    gridDot?.classList.toggle(styles.active);
    hrLine1?.classList.toggle(styles.active);
    slogan1?.classList.toggle(styles.active);
    hrLine2?.classList.toggle(styles.active);
    slogan2?.classList.toggle(styles.active);
    tube?.classList.toggle(styles.active);
    cube?.classList.toggle(styles.active);
    cubeWithBall?.classList.toggle(styles.active);

    e.preventDefault();
  }

  return (
    !pathname!.includes("admin")?
    <div className={styles.navContainer}>
      <Link href="/" passHref>
        <div className={styles.logo}>
          <Image
            src="v1648038246/fxvf2mulrdigxhm2pme6.png"
            alt="IAMPRESENTING (Pty) Ltd"
            width={"230%"}
            height={"160%"}
            priority
          />
        </div>
      </Link>
      <a aria-label='IAmPresenting HamBurger Menu' href="#" title="" className={styles.btn} onClick={navState}>
        <span></span>
        <span></span>
      </a>

      <div className={styles.menu}>
        <motion.div className="sloganLine">
          <hr className={styles.hrLine1} />
          <p className={styles.slogan1}>{bGdColor.slogan}</p>
          <hr className={styles.hrLine2} />
          <p className={styles.slogan2}>{bGdColor.slogan}</p>
        </motion.div>
        <motion.div className={styles.gridDot1} variants={fadeInUp}>
          <Image
            src="v1654900442/IAmPresenting/Home/fysfho5icnb2njjopimi.png"
            layout="responsive"
            height={"30%"}
            width={"80%"}
            className={styles.image}
            alt="IAmPresenting mission"
            priority
          />
        </motion.div>
        <motion.div className={styles.gridDot} variants={fadeInUp}>
          <Image
            src="v1654900442/IAmPresenting/Home/fysfho5icnb2njjopimi.png"
            layout="responsive"
            height={"30%"}
            width={"80%"}
            className={styles.image}
            alt="IAmPresenting mission"
            priority
          />
        </motion.div>
        <motion.div className={styles.cube} variants={fadeInUp}>
          <Image
            src="v1657142526/IAmPresenting/NavBar/qoxsmxfbsxslqgbqezdl.png"
            layout="responsive"
            height={"100%"}
            width={"100%"}
            className={styles.image}
            alt="IAmPresenting mission"
            priority
          />
        </motion.div>
        <motion.div className={styles.tube} variants={fadeInUp}>
          <Image
            src="v1657142586/IAmPresenting/NavBar/hd2hhfeaebsh9fnev5mp.png"
            layout="responsive"
            height={"100%"}
            width={"100%"}
            className={styles.image}
            alt="IAmPresenting mission"
            priority
          />
        </motion.div>
        <motion.div className={styles.cubeWithBall} variants={fadeInUp}>
          <Image
            src="v1657142666/IAmPresenting/NavBar/klh4xiw7snkvlwzyo0cf.png"
            layout="responsive"
            height={"100%"}
            width={"100%"}
            className={styles.image}
            alt="IAmPresenting mission"
            priority
          />
        </motion.div>

        <ul className={styles.menu_list}>
          {appBarNavList.map((item) => {
            return (
              <li className={styles.menu_item} onClick={navState} key={item.id}>
                <Link legacyBehavior href={item.route} key={item.id} passHref>
                  <a href="#" className={styles.menu_link}>
                    {item.navLinkTitle}
                  </a>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
    :
    null
  );
}
