import(/* webpackMode: "eager" */ "/app/app/components/Navigation/Appbar.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/components/Navigation/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Navigation/SideNavButton.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/components/styles/SideNav.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["div","Anime"] */ "/app/app/libraries/framer-motion.ts");
;
import(/* webpackMode: "eager" */ "/app/app/styles/globals.css");
